"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cleanAndNormalizeInput_1 = require("./helpers/cleanAndNormalizeInput");
var hasCorrectSize_1 = require("./validators/hasCorrectSize");
var isValidMobile_1 = require("./validators/isValidMobile");
var hasShadyPatterns_1 = require("./validators/hasShadyPatterns");
var validator = function (phone, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.mostrarLogs, mostrarLogs = _c === void 0 ? false : _c, _d = _b.validarTamanho, validarTamanho = _d === void 0 ? true : _d, _e = _b.codigoAreaPresente, codigoAreaPresente = _e === void 0 ? true : _e, _f = _b.codigoAreaPossivel, codigoAreaPossivel = _f === void 0 ? false : _f, _g = _b.codigoPaisPresente, codigoPaisPresente = _g === void 0 ? false : _g, _h = _b.codigoPaisPossivel, codigoPaisPossivel = _h === void 0 ? false : _h, _j = _b.permitirCelular, permitirCelular = _j === void 0 ? true : _j, _k = _b.bloquearPadroesIncomuns, bloquearPadroesIncomuns = _k === void 0 ? true : _k;
    try {
        var cleansed = cleanAndNormalizeInput_1.default(phone);
        if (validarTamanho &&
            !hasCorrectSize_1.default(cleansed, {
                hasArea: codigoAreaPresente,
                canHaveArea: codigoAreaPossivel,
                hasCountry: codigoPaisPresente,
                canHaveCountry: codigoPaisPossivel,
                canHaveNinthDigit: permitirCelular
            })) {
            return false;
        }
        if (permitirCelular && !isValidMobile_1.default(cleansed)) {
            return false;
        }
        if (bloquearPadroesIncomuns && hasShadyPatterns_1.default(cleansed)) {
            return false;
        }
        return true;
    }
    catch (error) {
        if (mostrarLogs) {
            console.error(error);
        }
    }
    return false;
};
exports.default = validator;
