"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cleanAndNormalizeInput = function (phone) {
    if (typeof phone === "number") {
        return "" + phone;
    }
    if (typeof phone === "string") {
        return phone.replace(/\D/g, "");
    }
    throw new Error("O input precisa ser string ou numérico");
};
exports.default = cleanAndNormalizeInput;
