"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var hasCorrectSize = function (phone, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.canHaveNinthDigit, canHaveNinthDigit = _c === void 0 ? true : _c, _d = _b.hasArea, hasArea = _d === void 0 ? true : _d, _e = _b.hasCountry, hasCountry = _e === void 0 ? false : _e, _f = _b.canHaveArea, canHaveArea = _f === void 0 ? false : _f, _g = _b.canHaveCountry, canHaveCountry = _g === void 0 ? false : _g;
    var minimum = [
        {
            enabled: hasArea,
            value: 2
        },
        {
            enabled: hasCountry,
            value: 2
        }
    ].reduce(function (total, _a) {
        var enabled = _a.enabled, value = _a.value;
        return (enabled ? total + value : total);
    }, 8);
    var maximum = [
        {
            enabled: canHaveNinthDigit,
            value: 1
        },
        {
            enabled: canHaveArea,
            value: 2
        },
        {
            enabled: canHaveCountry,
            value: 2
        }
    ].reduce(function (total, _a) {
        var enabled = _a.enabled, value = _a.value;
        return (enabled ? total + value : total);
    }, minimum);
    return phone.length >= minimum && phone.length <= maximum;
};
exports.default = hasCorrectSize;
