"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var hasShadyPatterns = function (phone, _a) {
    var _b = (_a === void 0 ? {} : _a).maxRepeatingDigits, maxRepeatingDigits = _b === void 0 ? 6 : _b;
    // checking for repeating numbers
    // overall 6 impossible sequential numbers should not be possible
    if (/(.)\1{5,}/g.test(phone)) {
        return true;
    }
    return false;
};
exports.default = hasShadyPatterns;
